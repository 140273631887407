<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('menu.projectInquiry.list')"
    class="px-5 py-3"
  >
    <base-dropdown-filter
      :items="filters"
      @changeFilter="changeFilter"
      label="Status Bantu Cari Hunian"
    >
    </base-dropdown-filter>
    <v-simple-table v-if="items && items.length > 0">
      <thead>
        <tr>
          <th class="primary--text">ID</th>
          <th class="primary--text">
            {{ $t('projectInquiry.name') }}
          </th>
          <th class="primary--text">
            {{ $t('projectInquiry.phone') }}
          </th>
          <th class="primary--text">
            {{ $t('projectInquiry.email') }}
          </th>
          <th class="primary--text">
            {{ $t('projectInquiry.status') }}
          </th>
          <th class="text-center primary--text">
            {{ $t('general.openDetail') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="item in items" :key="item.id">
          <td>{{ item.id }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.phone_number }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.status }}</td>
          <td>
            <base-detail-button
              :permissions="permissions"
              :basePath="basePath"
              :id="item.id"
            ></base-detail-button>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <pagination
      :push-state="false"
      :meta="meta"
      @changePage="changePage"
      :maxVisibleButtons="5"
      v-if="meta"
    />
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
const Pagination = () => import('@/components/utils/fractal-pagination');

export default {
  mixins: [HelperMixin],
  props: ['items', 'meta', 'permissions', 'filters'],
  components: {
    Pagination,
  },
  computed: {
    ...mapState({
      basePath: (state) => state.projectInquiry.basePath,
    }),
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page);
    },
    changeFilter(val) {
      this.$emit('changeFilter', val);
    },
  },
};
</script>
